















































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import BlockButtons from '@/components/BlockButtons.vue'

@Component
class BlockBanner extends Vue {
  @Prop() readonly title: String = ''
  @Prop() readonly linkText: String = ''
  @Prop() readonly url: String = ''
  @Prop() readonly alt: string = ''
  @Prop() readonly imageDesktop: String = ''
  @Prop() readonly imageMobile: String = ''
  @Prop() readonly hover: 'red' | '' = ''
  @Prop() readonly htmlText: string = ''
  @Prop() readonly actionButtons: ButtonAction[] = []
  static options: any
}
export default defineComponent({
  components: { BlockButtons, Link },
  props: BlockBanner.options.props,
  setup() {
    const sizes = {
      sm: [343, 258],
      md: [980, 326],
      lg: [1380, 459],
    }
    return {
      sizes,
    }
  },
})
