

























import { defineComponent } from '@nuxtjs/composition-api'
import { defineProps } from '@/helpers/defineProps'
import { BlockCardIconImageListProps } from '@/contracts/BlockCardIconImageList'

import CardIconImage from '@/components/Cards/CardIconImage.vue'
export default defineComponent({
  name: 'BlockCardIconImageList',
  components: { CardIconImage },
  props: defineProps<BlockCardIconImageListProps>({
    data: {
      default: () => [],
    },
  }),
  setup(props) {
    const mdWidth = props.data.length === 1 ? 8 : 4
    return {
      mdWidth,
    }
  },
})
