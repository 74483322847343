













import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { SectionCore } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockCardIconImageList from '@/components/CardLists/BlockCardIconImageList.vue'
import BlockBanner from '@/components/BlockBanner.vue'

@Component
class SectionCardImageIcon extends mixins(SectionCore) {
  @Prop() readonly bannerData: Object = {}
  @Prop() readonly cardsData: ReadonlyArray<any> = []
}

export default defineComponent({
  name: 'SectionCardImageIcon',
  components: {
    SectionBasic,
    BlockCardIconImageList,
    BlockBanner,
  },
  props: SectionCardImageIcon.options.props,
})
